<template>
  <div class="account-header">
    <h3>Account Details</h3>
    <button @click="closeDialog()">X</button>
  </div>
  <h1>{{ $store.state.balance }} ETH</h1>
  <div class="connect-wrapper">
    <p>Connected with MetaMask</p>
    <button @click="disconnectWallet()">Disconnect</button>
  </div>
  <div class="address-wrapper">
    <p>{{ shortenAddress($store.state.account0) }}</p>
    <div>
      <button @click="copyAdd($store.state.account0)">Copy Address</button>
      <a :href="urlOnEthScan" target="_blank">
        <button>View on Explorer</button>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import swal from "sweetalert";
import { useWallet, shortenAddress } from "vue-dapp";

const { disconnect } = useWallet();

export default {
  data() {
    return { shortenAddress };
  },
  methods: {
    ...mapActions({
      revertState: "restoreInitialState",
    }),
    disconnectWallet() {
      this.$router.push("/");
      this.revertState();
      disconnect();
      swal("Connect Wallet to use SwapWave!");
    },
    closeDialog() {
      this.$store.state.showAccDialog = false;
    },
    async copyAdd(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        swal("Success", "Copied", "success");
      } catch ($e) {
        swal("Error", "Cannot copy", "error");
      }
    },
  },
  computed: {
    urlOnEthScan() {
      return "https://arbiscan.io/address/" + this.$store.state.account0;
    },
  },
};
</script>

<style scoped>
a {
  color: var(--button-text-hover-color);
}

p {
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button {
  margin: 0.5rem 0rem;
}

.account-header button {
  padding: 0;
  height: 40px;
  min-width: 40px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 100%;
  font-weight: 900;
}

.connect-wrapper button,
.address-wrapper button {
  height: 30px;
  font-size: 12px;
  margin-left: 8px;
}
</style>
